import createAuthContext from './lib/createAuthContext'

const clientId = "5014ed57-49c1-4b9e-acc9-a94f5b7e0c36"
const clientSecret = "";//"1eeb36df-dfff-47b8-b0fb-437870fb9c3b"

//New Policy New Journey
//B2C_1A_PLUS_signup_signin

//Old Policy - new journey
//B2C_1A_Plus_signuporsignin

const provider = "https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_Plus_signuporsignin/oauth2/v2.0"
const tokenEndpoint = "https://nullmicgo.b2clogin.com/nullmicgo.onmicrosoft.com/B2C_1A_Plus_signuporsignin/oauth2/v2.0/token"






export const {AuthContext, Authenticated, useToken} = createAuthContext({
  clientId,
  clientSecret,
  provider
})
